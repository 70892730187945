.poweredby-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;

  margin-top: 20px;
  margin-bottom: 20px;

  a {
    all: initial;
    cursor: pointer;
  }

  img {
    width: 130px;
  }
}
