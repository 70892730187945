@import '../abstracts/variables.scss';
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');

// html {
//     height: -webkit-fill-available;
// }

body {
  //     display: flex;
  //     flex-direction: column;
  margin: 0;
  // height: 100vh;
  // min-height: -webkit-fill-available;
}

// #root {
//     flex: 1;
// }

body {
  background-color: $backgroundColor;
}

body * {
  font-family: 'Nunito Sans', serif;
}

.embedded-container {
  background-color: transparent;

  height: 100vh;
  min-height: -webkit-fill-available;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  border-bottom: none;
}
