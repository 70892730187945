#jump {
  position: sticky;
  // bottom: 40px;
  top: calc(100% - 120px);
  left: 50%;
  transform: translate(-50%, 0);
  animation: arrow-animation 3s infinite;
  cursor: pointer;
  z-index: 100;
  text-decoration: none;
  border: none;
  display: block;
  width: 72px;
  height: 72px;
  i {
    font-size: 72px;
    color: #001155;
  }
  @include max(md) {
  }
}

@keyframes arrow-animation {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 20px);
    opacity: 1;
  }
}
