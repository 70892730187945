// .nav {
//   height: 100px;

//   padding: 0 25%;

//   @include max(md) {
//     padding: 0 30px;
//   }

//   display: flex;
//   flex-direction: row;

//   border-bottom: solid 1px #ccc;
// }

.nav-logo {
  height: 100%;
  margin-right: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    height: 100%;
    width: 200px;

    .eseal-logo {
      width: 100%;
      height: 100%;
    }
  }

  @include max(md) {
    margin-right: 10px;

    a {
      width: 120px;
    }
  }
}

.navbar-content--right,
.navbar-content--left {
  min-width: 25%;
}

.navbar-content--right{
  display: flex;
  justify-content: flex-end;
}

// .nav-container {
//   height: 100%;
//   flex-grow: 1;

//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
// }

// .nav-left {
//   display: flex;
//   flex-direction: row;
//   gap: 20px;
//   align-items: center;

//   @include max(md) {
//     gap: 10px;
//   }
// }

// .nav-right {
//   display: flex;
//   flex-direction: row;
//   gap: 10px;
//   align-items: center;
// }

// .nav-link {
//   font-size: 20px;
// }

// .language-selection ul li button {
//   width: 50px !important;
// }
