.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button.transparent {
  border: 1px solid $color-button-primary;
  background-color: transparent;
  color: $color-button-primary;

  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.35);
  }
}

.has-error .button.transparent {
  border-color: #d12;
  color: #d12;
}

.button.button-md-full {
  @include max(md) {
    width: 100%;
    margin-top: 24px;
  }
}
