// breakpoint media queries
@mixin max($breakpoint) {
  @media screen and (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin min($breakpoint) {
  @media screen and (min-width: map-get($breakpoints, $breakpoint) + 1) {
    @content;
  }
}

//Function to convert px value into rem
//You can replace rem with any other value you want (rem is most preferred value for web)
@function rem($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}
