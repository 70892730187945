.dropzone--container {
  max-width: 680px;
  margin: 32px auto;
  text-align: center;
}

.dropzone {
  // min-height: 185px;
  background: #fff;
  border-radius: 10px;
  border: 1px dashed #666;
  box-sizing: border-box;
  padding: 30px 20px;

  &:focus,
  &:hover {
    cursor: pointer;
    border-color: #1781e3;
    outline: none;
  }

  &:active {
    transform: scale(0.98);
  }

  p {
    text-align: center;
    .has-error & {
      color: #d12;
    }
    &.drop {
      color: #1781e3;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    &.or {
      color: #666;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
}

.dropzone--container.is-inactive {
  cursor: not-allowed;
  .dropzone {
    border-color: rgba(#333, 0.5);
    pointer-events: none;
    user-select: none;
    p,
    p.drop,
    p.or {
      color: rgba(#333, 0.5);
    }
  }
}

.dropzone--container.is-drag {
  .dropzone {
    border-color: #1781e3;
    background: #d1e6f9;
  }
}

.dropzone--container.has-error {
  .dropzone {
    border-color: #d12;
    p,
    p.drop,
    p.or {
      color: #d12;
    }
  }
}

.dropzone--error {
  display: none;
  text-align: left;
  color: #d12;
  padding: 8px 0;

  .dropzone--container.has-error & {
    display: grid;
  }
  grid-template-columns: auto 1fr;
  grid-template-areas: 'icon text';

  .icon {
    font-size: 20px;
    line-height: 24px;
    grid-area: icon;
  }

  span {
    grid-area: text;
  }
}
