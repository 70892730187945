.nav-item {
  position: relative;
}

.language-selection {
  position: static;
  z-index: 10;
  width: 60px;
  height: 32px;

  &.open {
    ul {
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 4px;
      position: absolute;

      li {
        display: block;

        &.label {
          button {
            cursor: default;

            &:hover {
              color: #333333;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  ul {
    list-style: none;
    width: 100%;
    margin: 0;

    li {
      position: relative;
      width: 100%;
      display: none;
      user-select: none;

      button {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 32px 0 10px;
        color: #333333;
        font-size: 16px;
        line-height: 1.7em;
        white-space: nowrap;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          color: white;
          background-color: $color-papers-blue;
        }
      }

      &.active {
        display: none;
      }

      &.label {
        display: block;
        margin: 0;

        &:after {
          position: absolute;
          top: 0;
          right: 10px;
          width: 11px;
          height: 100%;
          content: '';
          background-image: url('/images/chevron.svg');
          background-size: 100% auto;
          background-position: 50% 48%;
          background-repeat: no-repeat;
          pointer-events: none;
        }

        button {
          &:hover {
            color: #333333;
            background-color: transparent;
          }
        }
      }
    }
  }
}
