form.paragraph {
  margin-top: 24px;
}

.component .wrapper .input {
  border: 1px solid rgb(102, 102, 102);
  border-radius: 5px;
  height: 48px;
  padding: 0px 16px;
  letter-spacing: -0.1px;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  line-height: inherit;
  font-family: inherit;
  font-size: 18px;
  font-weight: 300;
  position: relative;
  outline: none;
  background-color: rgb(255, 255, 255);
  width: 100%;
  color: rgb(51, 51, 51);
  user-select: text;
  appearance: none;
  display: flex;
  align-items: center;
  caret-color: rgb(8, 106, 219);
  cursor: inherit;
}

.component.invalid {
  & .wrapper .input {
    color: #d12;
    border: 1px solid #d12;
  }

  & .component {
    display: flex;
    color: #d12;
    font-size: 16px;
    margin-top: 6px;
    margin-left: -8px;
  }
}

.component .wrapper .input:required,
.component .wrapper .input:invalid {
  box-shadow: none;
}

.component .wrapper .input:focus {
  border-color: rgb(8, 106, 219);
}

.component.readonly .wrapper .input,
.component.disabled .wrapper .input {
  color: rgb(187, 187, 187);
  border-color: rgb(187, 187, 187);
  cursor: not-allowed;
}

.component.button-submit {
  text-align: right;
}
