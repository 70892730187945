@import '../abstracts/variables.scss';

h1 {
  font-size: 32px;
  line-height: 40px;
  color: $color-eseal-blue;
  font-weight: 700;
  margin-bottom: 8px;
  @include max(md) {
    font-size: 24px;
    line-height: 32px;
  }
}

h2,
.h2 {
  font-size: 56px;
  line-height: 64px;
  color: $color-eseal-blue;
  font-weight: 700;
  margin-bottom: 17px;
  @include max(md) {
    font-size: 32px;
    line-height: 40px;
  }
}

h3,
.h3 {
  font-size: 48px;
  line-height: 56px;
  color: $color-eseal-blue;
  font-weight: 700;
  margin-bottom: 17px;
  @include max(md) {
    font-size: 32px;
    line-height: 40px;
  }
}

p {
  margin-bottom: 24px;
  white-space: pre-wrap;
}

.text {
  max-width: 680px;
  margin: 0 auto;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  user-select: none;
}
