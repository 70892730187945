#guide {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1680px;
  background-size: cover;
  margin: 0 auto;
  padding: 82px 0;

  @include max(md) {
    padding: 24px 0 64px;
    align-items: flex-start;
  }
}

.guided-steps {
  $borderSize: 1px;
  margin-bottom: 36px;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li:not(.separator) {
      width: 140px;
      height: 140px;
      border: $borderSize solid #1781e3;
      border-radius: 50%;
      text-align: center;
      flex: 0 0 auto;
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
      &.cloud {
        background-image: url('/images/icon-cloud-140x140-2x.png');
      }
      &.search {
        background-image: url('/images/icon-search-140x140-2x.png');
      }
      &.check-mark {
        background-image: url('/images/icon-check-mark-140x140-2x.png');
      }
      i {
        color: #1781e3;
        font-size: 72px;
        line-height: 140px;
      }
      @include max(md) {
        width: 91px;
        height: 91px;
        i {
          font-size: 40px;
          line-height: 91px;
        }
      }
    }
    li.separator {
      width: 40px;
      height: $borderSize;
      background: #1781e3;
      @include max(md) {
        width: 20px;
      }
    }
  }
}

.infoBox {
  background: #cf4a0c;
  padding: 20px 20px 30px;
  text-align: center;
  margin: 28px 0 48px;
  border-radius: 4px;
  p {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
  i {
    display: block;
    margin: 4px auto 5px;
    font-size: 30px;
  }
}
