#form {
  min-height: calc(100vh - 80px);
  max-width: 1680px;
  background-size: cover;
  margin: 0 auto;
  padding-bottom: 82px;

  position: relative;
  width: 100%;
  max-width: 1680px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @include max(xl) {
  }

  @include max(lg) {
    padding: 24px 0;
  }

  @include max(md) {
    padding: 24px 0;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 150ms all cubic-bezier(0.33, 1, 0.68, 1);
  }

  &:before {
    z-index: 0;
    @include max(xl) {
    }
    @include max(lg) {
    }
    @include max(md) {
    }
  }

  &.step--1 {
    &:before {
      opacity: 0;
    }
  }

  .container {
    z-index: 10;
  }
}

.gcaptcha {
  margin: 45px 0;

  @include max(md) {
    margin: 16px 0;
  }

  > div > div {
    display: flex;
    place-content: center;
  }
}

#verify {
  margin: 56px 0 0;
  .loader-spinner--large {
    margin: 0 auto 24px;
  }
}
