// SCSS variables
// ---------------------------------------
// Breakpoint sizes
$breakpoints: (
  ul: 1440px,
  xl: 1280px,
  lg: 1024px,
  md: 768px,
  sm: 480px,
  xs: 320px,
) !default;

$color-navy: #094569;
$color-papers-blue: #43baff;
$color-dark-grey: #333333;
$color-mid-grey: #666666;
$color-light-grey: #999999;
$color-silver: #bbbbbb;
$color-divider: #dddddd;
$color-backgrounds: #f5f5f5;
$color-light-blue-grey: #eef3f6;
$color-dark-blue-grey: #dde3e7;
$color-red: #dd1122;
$color-error: $color-red;
$color-green: #00cc00;
$color-purple: #551166;
$color-light-purple: #801a99;
$color-white: #ffffff;
$color-black: #000;

$color-button-primary: #1781e3;
$color-button-primary-hover: #0851da;
$color-button-secondary: #cfd5d9;
$color-button-secondary-hover: #b1b9be;
$color-button-confirm: #25b252;
$color-button-confirm-hover: #008236;

/* NPE non-compliant */

// $color-footer: #f3f3f3;
$color-footer: #7ea6d8;
$color-form-background: #f8f8f8;
$color-form-background-red: #fce7e9;

$standard-easing: cubic-bezier(0.4, 0, 0.2, 1);
$button-easing: cubic-bezier(0.4, 0, 0.6, 1);

$image-path: '/images/';

$color-eseal-purple: #211e3b;
$purpa: #7140b1;
$bluba: #42baff;

$color-eseal-blue: #1e386a;
$color-eseal-azur: #83a5dc;

$backgroundColor: var(--backgroundColor, #fff);
$textColor: var(--textColor);
$accentColor: var(--accentColor, $color-papers-blue);
