table {
  margin: 44px 0 0;
  table-layout: fixed;
  & + & {
    margin-top: 20px;
  }
  &:last-of-type {
    margin-bottom: 33px;
  }
  tr {
    td {
      vertical-align: top;
      font-size: 18px;
      line-height: 24px;
      color: #333;
      border-bottom: 1px solid #bbbbbb;
      padding: 8px 0 6px;
      &:first-child {
        font-weight: 700;
      }
      &:last-child {
        text-align: right;
      }
      &[colspan='2'] {
        font-weight: 400;
        text-align: left;
        padding-right: 80px;
        @include max(md) {
          padding-right: 0;
        }
      }
      &.filename {
        hyphens: auto;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
    &.noBorder td {
      border-bottom: none;
    }
    &.stacked td {
      display: flex;
      flex-direction: row;
    }
  }
  b {
    font-weight: 700;
  }
  i.icon {
    font-size: 24px;
  }
}
