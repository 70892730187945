#embedded-form {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .container {
    position: relative;
    z-index: 10;
  }

  h1 {
    color: $accentColor;
  }

  h2,
  table,
  tr,
  td {
    color: $textColor;
  }

  .button {
    background-color: $accentColor;
    border: 1px solid $accentColor;
  }

  .dropzone {
    background-color: rgba(255, 255, 255, 0.05);
    border-color: $textColor;

    > * {
      color: $textColor;
    }

    .drop {
      color: $textColor;
    }

    .or {
      color: $textColor;
    }

    .button.transparent {
      background-color: transparent;
      border: 1px solid $accentColor;
    }
  }

  color: $textColor;
}

.gcaptcha {
  margin: 45px 0;

  @include max(md) {
    margin: 16px 0;
  }

  > div > div {
    display: flex;
    place-content: center;
  }
}

#verify {
  .loader-spinner--large {
    margin: 0 auto 24px;
  }
}

#analysis{
  .success{
    color: green;
  }
  .failure{
    color: red;
  }
}
