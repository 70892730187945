.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-top: 100px;

  .button {
    color: chocolate;
    border-color: chocolate;
  }
}

.error-title {
  color: chocolate;
}
