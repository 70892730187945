.sealedfileslist-container {
  all: unset;

  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-top: 15px;

  * {
    word-break: break-all;
  }

  .seal-list-item {
    border: 1px solid;
    border-radius: 25px;
    padding: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;

    .seal-list-left {
      display: flex;
      flex-direction: column;
      gap: 5px;

      > div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }
    }

    .seal-list-right {
      min-width: 25%;

      display: flex;
      flex-direction: column;
      justify-content: center;

      > div {
        align-self: flex-end;

        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;

        small {
          word-break: break-word;
        }

        @include max(md) {
          flex-direction: column;
        }
      }

      @include max(md) {
        min-width: 20%;
      }
    }
  }

  .seal-list-item--green {
    border-color: green;
  }

  .seal-list-item--red {
    border-color: red;
  }
}
