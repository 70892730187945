.accordion__container {
  margin: 44px 0 30px;

  .accordion {
    border-top: 1px solid #bbbbbb;
    border-bottom: 1px solid #bbbbbb;
    margin-bottom: -1px;

    .accordion__trigger {
      font-weight: 700;
      color: #001155;
      padding: 16px 0 14px;
      font-size: 24px;
      cursor: pointer;
      position: relative;

      &:after {
        content: '\E00D';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        font-family: sdx-icons;
      }

      &.is-open:after {
        content: '\E010';
      }
    }

    .accordion__contentInner {
      padding: 8px 10px 24px;
    }

    table tr:last-child td {
      border-bottom: none;
    }

    table {
      margin: 0;
    }
  }
}
